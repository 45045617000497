/* eslint-disable */

import { connect } from 'react-redux';
import { NewVersionPopupContainer as NewVersionPopupContainerSource, mapStateToProps, mapDispatchToProps } from 'SourceComponent/NewVersionPopup/NewVersionPopup.container';
import BrowserDatabase from 'Util/BrowserDatabase';
import { withRouter } from 'react-router';

const CACHE_LIFETIME =  5 * 60 * 1000;

export class NewVersionPopupContainer extends NewVersionPopupContainerSource {
  state = {
    appVersion: null,
    hasNewVersion: false,
  };
  
  componentDidMount() {
    const lastAppVersionRequestTime = BrowserDatabase.getItem('lastAppVersionRequestTime');
    if (!lastAppVersionRequestTime || Date.now() - lastAppVersionRequestTime > CACHE_LIFETIME) {
      this.sendRequestToServer();
    }
    this.interval = setInterval(this.sendRequestToServer, CACHE_LIFETIME);
    
    const { serviceWorker: { controller } = {} } = navigator;
    
    if (!controller) {
      return;
    }
    
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        this.reloadPage();
      });
    }
  };
  
  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.handleNavigate();
    });
  }
  
  handleNavigate = () => {
    if(this.state.hasNewVersion) {
      this.reloadPage();
    }
  };
  
  componentDidUpdate(prevProps, prevState) {
    if (prevState.appVersion && this.state.appVersion !== prevState.appVersion) {
      this.state.hasNewVersion = true;
    }
  }
  
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  
  reloadPage = () => {
    BrowserDatabase.setItem(Date.now(), 'lastAppVersionRequestTime', 0);
    clearInterval(this.interval);
    window.location.reload();
  }
  
  fetchAppVersion = () => {
    const query = `
    query {
      appVersion {
        version
      }
    }`;
    
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query }),
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data?.data?.appVersion?.version) {
        this.setState({ appVersion: data.data.appVersion.version });
      }
    }).catch(error => {
      console.error('Error fetching app version:', error);
    });
  }
  
  sendRequestToServer = () => {
    BrowserDatabase.setItem(Date.now(), 'lastAppVersionRequestTime', CACHE_LIFETIME);
    this.fetchAppVersion();
  };


render() {
    return '';
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewVersionPopupContainer));
