/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */

import { connect } from 'react-redux';
import { ProductCardContainer as SourceProductCardContainer, mapStateToProps, mapDispatchToProps } from 'HoeksComponent/ProductCard/ProductCard.container';
export * from 'HoeksComponent/ProductCard/ProductCard.container';

/** @namespace Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    _getThumbnail() {
        const product = this._getProductOrVariant();
        const { small_image: { url, path } = {} } = product;
        if (this._isThumbnailAvailable(path)) {
            return url;
        }
    
        // If thumbnail is, missing we try to get image from parent
        const { product: { small_image: { url: parentUrl } = {} } } = this.props;
        if (this._isThumbnailAvailable(parentUrl)) {
            return parentUrl;
        }
    
        return '';
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
