/* eslint-disable */
import { formatPrice } from 'Util/Price';
import ProductCustomizableOptions from 'HoeksComponent/ProductCustomizableOptions';
import ProductCustomizableOption from 'HoeksComponent/ProductCustomizableOption';

const renderOptionLabel = (args, callback, instance) => {
    const [priceType, price] = args;

    const {
        currency
    } = instance.props;

    switch (priceType) {
        case 'PERCENT':
            return `${ price }%`;
        default:
            return formatPrice(price, currency);
    }
};

const renderCustomizableOptions = (args, callback, instance) => {
    const {
        product: {
            options,
            price_range: {
                minimum_price: {
                    final_price: {
                        currency
                    } = {}
                } = {}
            } = {}
        },
        getSelectedCustomizableOptions,
        productOptionsData,
        device
    } = instance.props;

    if (device.isMobile) {
        return null;
    }

    return (
        <section
          block="ProductActions"
          elem="Section"
          mods={ { type: 'customizable_options' } }
        >
            <ProductCustomizableOptions
              options={ options }
              getSelectedCustomizableOptions={ getSelectedCustomizableOptions }
              productOptionsData={ productOptionsData }
              currency={ currency }
            />
        </section>
    );
}

const renderContent = (args, callback, instance) => {
    const {
        options,
        productOptionsData,
        setSelectedCheckboxValues,
        setCustomizableOptionTextFieldValue,
        setSelectedDropdownValue,
        currency
    } = instance.props;

    return options.map((option, key) => (
        <ProductCustomizableOption
          option={ option }
          /* eslint-disable-next-line react/no-array-index-key */
          key={ key }
          setSelectedCheckboxValues={ setSelectedCheckboxValues }
          setCustomizableOptionTextFieldValue={ setCustomizableOptionTextFieldValue }
          setSelectedDropdownValue={ setSelectedDropdownValue }
          productOptionsData={ productOptionsData }
          currency={ currency }
        />
    ));
}

const renderMobileCustomizableOptions = (args, callback, instance) => {
    const {
        dataSource: {
            options,
            price_range: {
                minimum_price: {
                    final_price: {
                        currency
                    } = {}
                } = {}
            } = {}
        },
        getSelectedCustomizableOptions,
        productOptionsData,
        device
    } = instance.props;

    if (!device.isMobile) {
        return null;
    }

    return (
        <ProductCustomizableOptions
          options={ options || [] }
          getSelectedCustomizableOptions={ getSelectedCustomizableOptions }
          productOptionsData={ productOptionsData }
          currency={ currency }
        />
    );
}

export default {
    'Component/ProductCustomizableOption/Container': {
        'member-function': {
            renderOptionLabel
        }
    },
    'Component/ProductActions/Component': {
        'member-function': {
            renderCustomizableOptions
        }
    },
    'Component/ProductCustomizableOptions/Component': {
        'member-function': {
            renderContent
        }
    },
    'Route/ProductPage/Component': {
        'member-function': {
            renderCustomizableOptions: renderMobileCustomizableOptions
        }
    }
};