/* eslint-disable */

// imports the original component from vendor folder
import { NewsletterSubscription as SourceNewsletterSubscription } from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';

// import new extended styles file
// import './NewsletterSubscription.override.style.scss';

export class NewsletterSubscription extends SourceNewsletterSubscription {
    renderActions() {
        return (
            <button
              type="submit"
              block="Button"
              mix={ { block: 'FieldForm', elem: 'Button' } }
              aria-label={ __('Submit') }
            >
                { __('Sign Up') }
            </button>
        );
    }
}

export default NewsletterSubscription;
