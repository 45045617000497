/* eslint-disable */
import SourceProductCustomizableOption from 'HoeksComponent/ProductCustomizableOption/ProductCustomizableOption.component';
import ExpandableContentShowMore from 'Component/ExpandableContentShowMore';
import ExpandableContent from 'Component/ExpandableContent';

/** @namespace Component/ProductCustomizableOption/Component */
export class ProductCustomizableOption extends SourceProductCustomizableOption {
    renderCheckboxValues() {
        const { option: { required, data } } = this.props;

        return (
            <>
                <ExpandableContentShowMore>
                    { data.map(this.renderOptionCheckboxValue) }
                </ExpandableContentShowMore>

                { this.renderRequired(required) }
            </>
        );
    }
}

export default ProductCustomizableOption;
