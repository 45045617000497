/* eslint-disable */

// // imports the original component from vendor folder
// import Header from 'SourceComponent/Header/Header.component';

// // import new extended styles file
// import './Header.override.style.scss';

// export default Header;

import { Header as SourceHeader } from 'SourceComponent/Header/Header.component';
export * from 'SourceComponent/Header/Header.component';

import {
    CART_OVERLAY,
    CUSTOMER_WISHLIST
} from 'Component/Header/Header.config';

import CmsBlock from 'Component/CmsBlock';
import Link from 'Component/Link';
import { appendWithStoreCode } from 'Util/Url';
import history from 'Util/History';

// import new extended style file
import './NewHeader.override.style.scss';

export class Header extends SourceHeader {
    state = { scrolled: false }
    // usp header banner
    renderContacts() {
        return (
            <CmsBlock identifier={ "header_usp" } />
        );
    }

    renderSearchField(isVisible = false) {
        const { searchCriteria } = this.props;
        return (
            <div block="Header-HrSearch" mods={ { mobileActive: isVisible } } key="search">
                <input block="Header-HrSearch" mix={ { block: "addwish-search-input" } }  elem="Input" type="text"
                       id="clerk_search"
                       name="hr-search"
                       onKeyDown={ this.onSearchEnterPress }
                       onChange={ this.handleChange }
                       placeholder="Find din nye cykel her..."
                       value={ searchCriteria }
                />
                <span block="Header-HrSearch" elem="Icon"></span>
            </div>
        );
    }

    /**
     * update input value
     * @param e
     */
    handleChange = (e) => {
        const { target: { value } } = e;
        const { onSearchBarChange } = this.props;
        onSearchBarChange(e);

        this.setState({ isPlaceholderVisible: value === '' });
    };

    /**
     * on search reload page to upload data from clekr.io
     * @param e
     */
    onSearchEnterPress = (e) => {
        const { searchCriteria } = this.props;
        const searchQuery = searchCriteria.trim().replace(/\s\s+/g, '%20');
        const trimmedSearch = searchCriteria.trim();
        if (e.key === 'Enter' && trimmedSearch !== '') {
            history.push(appendWithStoreCode(`/search/${ searchQuery }`));
            window.location.reload(true);
        }
    };

    /**
     * clerk io remplate render
     *
     * @returns {JSX.Element}
     */
    renderSearchResultClerkIO() {
        return (
            <span className="clerk"
                  data-template="@search"

                  data-instant-search="#clerk_search"
                  data-instant-search-suggestions="3"
                  data-instant-search-categories="6"
                  data-instant-search-pages="6"

                  data-instant-search-positioning="center"
            />
        );
    }


    renderStoreBtn() {
        return (
            <div block="Header" elem="StoreBtnContainer">
                <Link
                    to="/butikker"
                    block="Header"
                    elem="StoreBtn"
                >
                    <span block="Header" elem="StoreTitle">Butik</span>
                    <span block="Header" elem="StoreIcon"></span>
                </Link>
            </div>
        );
    }

    componentDidMount(){
        window.addEventListener('scroll', () => {
           const scrolled = (window.scrollY !== 0);
           this.setState({ scrolled });
        });
    }

    renderMap = {
        cancel: this.renderCancelButton.bind(this),
        back: this.renderBackButton.bind(this),
        close: this.renderCloseButton.bind(this),
        share: this.renderShareWishListButton.bind(this),
        title: this.renderTitle.bind(this),
        logo: this.renderLogo.bind(this),
        search: this.renderSearchField.bind(this),
        searckResult: this.renderSearchResultClerkIO.bind(this),
        minicart: this.renderMinicart.bind(this),
        account: this.renderAccount.bind(this),
        store: this.renderStoreBtn.bind(this),
        clear: this.renderClearButton.bind(this),
        edit: this.renderEditButton.bind(this),
        ok: this.renderOkButton.bind(this)
    };

    render() {
        const { stateMap } = this;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            device
        } = this.props;

        const {
            scrolled
        } = this.state

        if (!device.isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        return (
                <section block="Header" elem="Wrapper" mods={ { name, scrolled } }>
                    <header
                      block="Header"
                      mods={ { name, isHiddenOnMobile, isCheckout, scrolled } }
                      mix={ { block: 'FixedElement', elem: 'Top' } }
                      ref={ this.logoRef }
                    >
                        { this.renderTopMenu() }
                        <nav block="Header" elem="Nav">
                            { this.renderNavigationState() }
                        </nav>
                        { this.renderMenu() }
                    </header>
                </section>
        );
    }

}

export default Header;
