/* eslint-disable */
import { ProductPriceContainer as HoeksProductPriceContainer, mapStateToProps, mapDispatchToProps } from 'HoeksComponent/ProductPrice/ProductPrice.container';

import { connect } from 'react-redux';

import {
    formatPrice,
} from 'Util/Price';

import ProductPrice from './ProductPrice.component';
import {
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_BOTH,
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX
} from 'Component/ProductPrice/ProductPrice.config';

export class ProductPriceContainer extends HoeksProductPriceContainer {
    getFormattedFinalPrice() {
        const {
            price: {
                minimum_price: {
                    final_price: {
                        value: minimalPriceValue,
                        currency: priceCurrency
                    } = {},
                    final_price_excl_tax: {
                        value: minimalPriceExclTaxValue
                    } = {}
                } = {}
            } = {},
            displayTaxInPrice = ''
        } = this.props;
        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX) {
            return formatPrice(minimalPriceExclTaxValue, priceCurrency);
        }
        return formatPrice(minimalPriceValue, priceCurrency);
    }

    getFormattedSubPrice() {
        const {
            price: {
                minimum_price: {
                    final_price: {
                        currency: priceCurrency = ''
                    },
                    final_price_excl_tax: {
                        value: minimalPriceExclTaxValue = 0
                    } = {}
                } = {}
            } = {},
            displayTaxInPrice = ''
        } = this.props;

        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_BOTH) {
            return formatPrice(minimalPriceExclTaxValue, priceCurrency);
        }

        return null;
    }

    

    overriddenContainerProps = () => {
        const {
            price: {
                minimum_price: {
                    discount: {
                        percent_off: discountPercentage
                    } = {},
                    final_price: {
                        value: minimalPriceValue,
                        currency: priceCurrency
                    } = {},
                    regular_price: {
                        value: regularPriceValue
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!minimalPriceValue) {
            return {};
        }

        const roundedRegularPrice = this.getRoundedRegularPrice();
        const formattedFinalPrice = this.getFormattedFinalPrice();
        const formattedSubPrice = this.getFormattedSubPrice();

        return {
            roundedRegularPrice,
            priceCurrency,
            discountPercentage,
            formattedFinalPrice,
            formattedSubPrice
        };
    }

    render() {
        return (
            <ProductPrice
              { ...this.props }
              { ...this.overriddenContainerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);