/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable no-unused-vars */

import { PureComponent } from 'react';
import { withRouter } from 'react-router';

import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';

export class PageViewEventPlugin extends PureComponent {
    componentDidMount() {
        // eslint-disable-next-line react/prop-types
        const { history } = this.props;
        history.listen((location) => {
            if (window.ADDWISH_PARTNER_NS) {
                window.ADDWISH_PARTNER_NS.api.reload();
            }
        });
    }

    render() {
        return null;
    }
}

export const PageView = withRouter(PageViewEventPlugin);

const pageViewEvent = (member) => [
    ...member,
    {
        component: <PageView />,
        position: 50
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: pageViewEvent
        }
    }
};
