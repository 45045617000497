/* eslint-disable */
const renderAddToCart = (args, callback, instance) => {
    const {
        mix,
        product: { type_id, stock_status, variants },
        isLoading,
        buttonClick,
    } = instance.props;

    var btnDisabled = false;

    if (type_id == "configurable") {
        btnDisabled = !isConfigurableInStock(variants);
    }

    if (type_id == "simple" && stock_status != "IN_STOCK") {
        btnDisabled = true;
    }

    if (isLoading) {
        btnDisabled = true;
    }

    if (!type_id) {
        instance.renderPlaceholder();
    }

    return (
        <button
            onClick={buttonClick}
            block="Button AddToCart"
            mix={mix}
            mods={{ isLoading }}
            disabled={btnDisabled}
        >
            <span>{__('Add to cart')}</span>
            <span>{__('Adding...')}</span>
        </button>
    );
}

const isConfigurableInStock = (variants) => {
    return variants.some(({stock_status}) => stock_status == "IN_STOCK");
}

export default {
    'Component/AddToCart/Component': {
        'member-function': {
            'render': renderAddToCart
        }
    }
}