/* eslint-disable */

// imports the original component from vendor folder
import HoeksMenu from 'HoeksComponent/Menu/Menu.component';
import CmsBlock from 'Component/CmsBlock';
import Link from 'Component/Link';
// import new extended styles file
import './Menu.override.style.scss';
import { getSortedItems } from 'Util/Menu';

export class Menu extends HoeksMenu {


    renderPromotionCms() {
        const { closeMenu } = this.props;
        const { header_content: { header_cms } = {} } = window.contentConfiguration;

        if (header_cms) {
            return <CmsBlock identifier={ header_cms } blockType={ HEADER_CMS_BLOCK } />;
        }

        return (
            <div block="Menu" elem="Promotion">
                <h3 block="Menu" elem="PageLink">
                    <Link
                      to="/handelsvilkaar"
                      onClick={ closeMenu }
                      block="Menu"
                      elem="Link"
                    >
                        <img src="/media/hoeks-pwa/icons/ikon_14.svg"></img>
                        { __('14 dages fri ombytning') }
                    </Link>
                </h3>

                <h3 block="Menu" elem="PageLink">
                    <Link
                      to="/prismatch"
                      onClick={ closeMenu }
                      block="Menu"
                      elem="Link"
                    >
                        <img src="/media/hoeks-pwa/icons/ikon_forstoerrelsesglas.svg"></img>
                        { __('Altid prismatch') }
                    </Link>
                </h3>
                <h3 block="Menu" elem="PageLink">
                    <Link
                      to="/levering-100-samlet"
                      onClick={ closeMenu }
                      block="Menu"
                      elem="Link"
                    >
                        <img src="/media/hoeks-pwa/icons/ikon_svensknoegle.svg"></img>
                        { __('Leveres samlet & køreklar') }
                    </Link>
                </h3>
                <div block="Menu" elem="Social">
                    {/*<CmsBlock identifier="social-links" />*/}
                </div>
            </div>
        );
    }

    renderSubLevel(category) {
        const { activeMenuItemsStack, device } = this.props;
        const { item_id, children } = category;
        const childrenArray = getSortedItems(Object.values(children));
        const isVisible = activeMenuItemsStack.includes(item_id);
        const subcategoryMods = { type: 'subcategory' };
        if (category.parent_id == 1) {
            let text = 'Se alle ';
            if (Object.keys(category.children).length > 0 && device.isMobile) {
                if(category.item_id == 6 || category.item_id == 4) {
                    text = 'Se alt ';
                }
                category.children[0] = {
                    children : {},
                    icon : null,
                    is_active : true,
                    item_class : '',
                    item_id : category.item_id,
                    parent_id : category.item_id,
                    position : 0,
                    title : text + category.title.toLowerCase(),
                    url : category.url
                }
            }
            childrenArray.forEach(function (category) {
                    if (Object.keys(category.children).length > 0 && device.isMobile) {
                        category.children[0] = {
                            children : {},
                            icon : null,
                            is_active : true,
                            item_class : '',
                            item_id : category.item_id,
                            parent_id : category.item_id,
                            position : 0,
                            title : 'Se alle ' + category.title.toLowerCase(),
                            url : category.url
                        }
                    }
                }
            );
        }
        return (
            <div
                block="Menu"
                elem="SubMenu"
                mods={ { isVisible } }
                key={ item_id }
            >
                <div
                    block="Menu"
                    elem="ItemList"
                    mods={ { ...subcategoryMods } }
                >
                    { childrenArray.map(this.renderSubLevelItems) }
                </div>
            </div>
        );
    }
}

export default Menu;
