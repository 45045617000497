/* eslint-disable */
import { ProductCard as HoeksProductCard } from 'HoeksComponent/ProductCard/ProductCard.component';
import './ProductCard.override.style.scss';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import TextPlaceholder from 'Component/TextPlaceholder';
import ProductPrice from 'Component/ProductPrice';
import { BUNDLE, CONFIGURABLE } from 'Util/Product';
export * from 'HoeksComponent/ProductCard/ProductCard.component';

const isCampaignActive = false;

/**
 * Product card
 * @class ProductCard
 * @namespace Component/ProductCard/Component
 */
export class ProductCard extends HoeksProductCard {

    renderBrandValue() {
        const {
            getAttribute,
            siblingsHaveBrands,
            setSiblingsHaveBrands
        } = this.props;

        const brand = getAttribute('brand');

        if (!brand) {
            return null;
        }

        if (!siblingsHaveBrands) {
            setSiblingsHaveBrands();
        }

        return (
            <ProductAttributeValue
                attribute={brand}
                isFormattedAsText
                mix={{
                    block: 'ProductCard',
                    elem: 'BrandAttributeValue'
                }}
            />
        );
    }

    renderDiscountSplat() {
        if (isCampaignActive) {
            return null;
        }

        const {
            product: {
                price_range
            }

        } = this.props;
        if (!price_range) return null;
        const {
            minimum_price: {
                discount: {
                    percent_off: discountPercentage }
            }
        } = price_range;

        if (!Math.floor(discountPercentage)) {
            return (
                <div block="ProductCard" elem="DiscountSplat" mods={{ noDiscount: true }}></div>
            );
        }

        return (
            <div block="ProductCard" elem="DiscountSplat">
                <span>-{Math.floor(discountPercentage)}%</span>
            </div>
        );
    }

    renderOnSaleTag() {
        if (!isCampaignActive) {
            return null;
        }

        const {
            product: {
                is_on_sale: isOnSale,
                price_range
            }
        } = this.props;

        if (!isOnSale || !price_range) {
            return null;
        }
        const {
            minimum_price: {
                discount: {
                    percent_off: discountPercentage }
            }
        } = price_range;

        return (
            <>
                <div block="ProductCard" elem="OnSaleTag">
                    <span>{__('Januar Udsalg')}</span>
                </div>
                <div block="ProductCard" elem="OnSalePercent">
                    <span>-{Math.floor(discountPercentage)}%</span>
                </div>
            </>
        );
    }

    renderVisualConfigurableOptions() {
        return null;
    }

    renderAvaibleOptions() {
        const {product: {price_range, placeholder } } = this.props;
        if (!price_range || placeholder) return <div block="ProductCard" elem="Stock" mods={{empty: true}}></div>;
        return (
            <div block="ProductCard" elem="Stock" mods={{outOfStock: !this._getIsProductInStock()}}>
                <div block="ProductCardStockStatus" elem="StockIcon"></div>
                <div block="ProductCardStockStatus" elem="StockLabel">{ this._getStockLabel() } </div>
            </div>
        );
    }

    _getStockLabel() {
        const {
            product: {
                stock_variants,
                type_id
            }
        } = this.props;

        if (!this._getIsProductInStock()){
            return __('Not in stock');
        }
        if (type_id == "configurable"){
            return __('%s in stock', stock_variants.join(', '));
        }
        return __('In stock');

    }

    _getIsProductInStock(){
        const {
            product: {
                stock_variants,
                stock_status,
                type_id
             }
        } = this.props;

        if (type_id === "configurable"){
            return stock_variants && stock_variants.length;
        }
        return stock_status === "IN_STOCK";
    }

    renderProductPrice() {
        const {
            product: { type_id, variants },
            isConfigurableProductOutOfStock,
            isBundleProductOutOfStock
        } = this.props;
        const price_range = this.getPriceRange();
        if (!price_range) {
            return <TextPlaceholder />;
        }

        switch (type_id) {
            case CONFIGURABLE:
                break;
            case BUNDLE:
                if (isBundleProductOutOfStock()) {
                    return this.renderEmptyProductPrice();
                }
                break;
            default:
                break;
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                {this.renderTierPrice()}
                {this.renderConfigurablePriceBadge()}
                <ProductPrice
                    price={price_range}
                    mix={{ block: 'ProductCard', elem: 'Price' }}
                />
            </div>
        );
    }

    getPriceRange(){
        const {
            product: { price_range, variants }
        } = this.props;

        if (variants && variants.length) {
            const { price_range: variant_price_range } = variants[0];
            const { minimum_price: { discount: { percent_off: discountPercentage } } } = price_range;
            const { minimum_price: { discount: { percent_off: variantDiscount } } } = variant_price_range;
            if (discountPercentage == 0 && variantDiscount > 0){
                return variant_price_range;
            }
        }
        return price_range;
    }

    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <>
                    {this.renderDiscountSplat()}
                    <div block="ProductCard" elem="FigureReview">
                        {this.renderOnSaleTag()}
                        <figure block="ProductCard" elem="Figure">
                            {this.renderPicture()}
                        </figure>
                        {this.renderReviews()}
                    </div>
                    <div block="ProductCard" elem="Content">
                        {this.renderAdditionalProductDetails()}
                        {this.renderMainDetails()}
                        {this.renderProductPrice()}
                        {this.renderAvaibleOptions()}
                        {this.renderVisualConfigurableOptions()}
                    </div>
                </>
            ))
        );
    }
}

export default ProductCard;
