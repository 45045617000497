/* eslint-disable */
import { MenuItem as SourceMenuItem } from 'SourceComponent/MenuItem/MenuItem.component';
import Link from 'Component/Link';

export class MenuItem extends SourceMenuItem {
    renderItemLinkContent() {
        const {
            activeMenuItemsStack,
            item,
            itemMods,
            handleCategoryHover,
            onItemClick
        } = this.props;
        const {
            url,
            item_id,
            item_class: itemClass
        } = item;
        const isHovered = activeMenuItemsStack.includes(item_id);

        return (
            <Link
                to={ url }
                block="Menu"
                elem="Link"
                id={ item_id }
                onMouseEnter={ handleCategoryHover }
                mods={ { isHovered } }
                onClick={ onItemClick }
                className={ itemClass ? itemClass : '' }
            >
            { this.renderItemContent(item, itemMods) }
            </Link>
        );
    }
}

export default MenuItem;
