/* eslint-disable */

import CmsBlock from 'Component/CmsBlock';
// imports the original component from vendor folder
import { Footer as SourceFooter } from 'SourceComponent/Footer/Footer.component';

// import new extended styles file
import './Footer.override.style.scss';

import { COLUMN_MAP, NEWSLETTER_COLUMN, RENDER_NEWSLETTER } from './Footer.config';

export class Footer extends SourceFooter {
    renderCopyrightContent() {
        return (
            <CmsBlock identifier={ "footer-copyright" } />
        );
    }

    renderCmsBlockWrapper() {
        const { footer_content: { footer_cms } = {} } = window.contentConfiguration;
        return (
            <div
              block="Footer"
              elem="CmsBlockWrapper"
              mix={ { block: 'Footer', elem: 'Content' } }
            >
                <div
                  block="Footer"
                  elem="Columns"
                  mix={ { block: 'ContentWrapper' } }
                >
                    <CmsBlock identifier={ footer_cms } />
                </div>
            </div>
        );
    }

    renderNewsletterBlock() {
        return (
            <>
                <div className="cms-section">
                    <div className="cms-section-container">
                        <div className="cms-row">
                            <div className="cms-col cms-banner cms-col-12">
                                <div className="footer-newsletter-section">
                                        <div className="footer-newsletter-section__content">
                                            <CmsBlock identifier={ "newsletter-content-left" } />
                                        </div>
                                        <div className="footer-newsletter-section__form">
                                            <div class="footer-newsletter-section__form__v-center">
                                                { this.renderColumn({
                                                    ...NEWSLETTER_COLUMN,
                                                    mods: { isNewsletter: true }
                                                }) }
                                                <CmsBlock identifier={ "newsletter-content-privacy" } />
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    render() {
        // commit because we always show footer
        // const { isVisibleOnMobile, device } = this.props;

        // if (!isVisibleOnMobile && device.isMobile) {
        //     return null;
        // }

        // if (isVisibleOnMobile && !device.isMobile) {
        //     return null;
        // }

        return (
            <>
            <CmsBlock identifier={ "cms-trust-pilot-large" } />
            <CmsBlock identifier={ "cms-usp-banner-new-above-footer" } />
            <footer block="Footer" aria-label="Footer">
                {/* <CmsBlock identifier={ "footer-newsletter-block" } /> */}
                { this.renderNewsletterBlock() }
                <CmsBlock identifier={ "footer-support-block" } />
                { this.renderContent() }
                { this.renderCopyrightContent() }
            </footer>
            </>
        );
    }

}

export default Footer;
