/* eslint-disable */

import { ProductListQuery as SourceProductListQuery } from 'HoeksQuery/ProductList.query';
export * from 'HoeksQuery/ProductList.query';
/**
 * Product List Query
 * @class ProductListQuery
 * @namespace Query/ProductList
 */
export class ProductListQuery extends SourceProductListQuery {
  _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
    return [
      ...super._getProductInterfaceFields(isVariant, isForLinkedProducts),
      'hks_expected_date',
      'hks_vendor_stock_date',
      'is_on_sale'
    ];
  }

  _getAttributeFields(isVariant) {
    return super._getAttributeFields(false);
  }

  _getConfigurableProductFragmentFields(){
    return [
      ...super._getConfigurableProductFragmentFields(),
      'stock_variants'
    ];
  }

}

export default new ProductListQuery();
