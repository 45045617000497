/* eslint-disable */
import Field from 'Component/Field';
import { CHECKBOX_TYPE } from 'Component/Field/Field.config';
import { PureComponent } from "react";
import { deleteGuestQuoteId, getGuestQuoteId } from 'Util/Cart';
import CheckoutQuery from 'Query/Checkout.query';
import NewsletterSubscriptionQuery from 'Query/NewsletterSubscription.query';
import { debounce, fetchMutation, fetchQuery } from 'Util/Request';
import { isSignedIn } from 'Util/Auth';
import {
    BILLING_STEP
} from 'Route/Checkout/Checkout.config';

export class NewsLetterCheckoutPlugin extends PureComponent {
    onNewsLetterChange(x, value) {
        this.setState({ newsLetterChecked: value });
    }
}

const { onNewsLetterChange } = new NewsLetterCheckoutPlugin();

const addToContainerFunctions = (member, instance) => {
    return (
        {
            ...member,
            onNewsLetterChange: onNewsLetterChange.bind(instance)
        }
    )
}

const saveGuestEmail = (args, callback, instance) => {
    const { email, newsLetterChecked } = instance.state;
    const { updateEmail } = instance.props;
    const guestCartId = getGuestQuoteId();
    const mutation = CheckoutQuery.getSaveGuestEmailMutation(email, guestCartId);

    updateEmail(email);
    return fetchMutation(mutation).then(
        ({ setGuestEmailOnCart: data }) => {
            if (data) {
                instance.setState({ isGuestEmailSaved: true });

                if (newsLetterChecked) {
                    const subscribtionResult = subscribeToNewsletter(email);
                }
            }

            return data;
        },
        instance._handleError
    );
}

const subscribeToNewsletter = (email) => {
    const mutation = NewsletterSubscriptionQuery.getSubscribeToNewsletterMutation(email);
    return fetchMutation(mutation).then(result => {
        return result;
    })
}


const renderGuestForm = (args, callback, instance) => {
    const { onNewsLetterChange, checkoutStep } = instance.props;
    const isBilling = checkoutStep === BILLING_STEP;
    return (
        <>
            {callback(...args)}
            {!isBilling && !isSignedIn() ? (
                <Field
                    id="NewsLetter_Toggle"
                    name="NewsLetter_Toggle"
                    type={CHECKBOX_TYPE}
                    onChange={onNewsLetterChange}
                    label={__("Subscribe to newsletter")}
                />
            ) : (<div></div>)}
        </>
    );
}

const saveAddressInformation = (args, callback, instance) => {
    return (callback(...args));
}

export default {
    'Route/Checkout/Container': {
        'member-property': {
            'containerFunctions': addToContainerFunctions
        },
        'member-function': {
            'saveGuestEmail': saveGuestEmail,
            'saveAddressInformation': saveAddressInformation
        }
    },
    'Route/Checkout/Component': {
        'member-function': {
            'renderGuestForm': renderGuestForm
        }
    }
}
