/* eslint-disable */

import { ProductAttributeValue as SourceProductAttributeValue } from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

// import new extended style file
import './ProductAttributeValue.override.style.scss';
import { STRING_ONLY_ATTRIBUTE_CODES } from './ProductAttributeValue.config';

export class ProductAttributeValue extends SourceProductAttributeValue { 

    renderSelectAttribute() {
        const { attribute: { attribute_value, attribute_code } } = this.props;
        const attributeOption = this.getOptionLabel(attribute_value);
        const { label, swatch_data } = attributeOption;

        // don't display swatches for brands 
        if(attribute_code === 'brand') {
            return this.renderStringValue(label || __('N/A'));
        } 


        if (!swatch_data || STRING_ONLY_ATTRIBUTE_CODES.includes(attribute_code)) {
            return this.renderStringValue(label || __('N/A'));
        }

        const { value, type } = swatch_data;

        switch (type) {
        case '0':
            return this.renderStringValue(value, label);
        case '1':
            return this.renderColorValue(value, label);
        case '2':
            return this.renderImageValue(value, label);
        default:
            return this.renderStringValue(label || __('N/A'));
        }
    }   

}


export default ProductAttributeValue;