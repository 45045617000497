/* eslint-disable */
import { CheckoutSuccess as SourceCheckoutSuccess } from "SourceComponent/CheckoutSuccess/CheckoutSuccess.component";
import {ClerkComponent as Clerk} from '@sdm/clerk/src/component/Clerk/Clerk.component';

export class CheckoutSuccess extends SourceCheckoutSuccess {

    componentDidUpdate(prevProps) {
        const { checkoutStep } = this.props;
        const { checkoutStep: prevCheckoutStep } = prevProps;

        if (checkoutStep !== prevCheckoutStep) {
            this.updateHeader();
            this.updateStep();
        }
    }

    render() {
        //const { orderID } = this.props;
        const { orderID = '', email = '', items = '' } = this.props;
        if (orderID && email &&  items) {
            const clerk = new Clerk(this.props);
            clerk.sentSale(orderID, items, email)
        }
        return (
            <div block="CheckoutSuccess">
                <h3>{ __('Your order # is: %s', orderID) }</h3>
                <p>{ __('We`ll email you an order confirmation with details and tracking info.') }</p>
                { this.renderButtons() }
            </div>
        );
    }
}

export default CheckoutSuccess;
