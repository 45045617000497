/* eslint-disable */

// imports the original component from vendor folder
import { ProductPrice  as SourceProductPrice  } from 'SourceComponent/ProductPrice/ProductPrice.component';


// import new extended styles file
import './ProductPrice.override.style';

export class ProductPrice extends SourceProductPrice  {

    render() {
        const {
            price: {
                minimum_price: {
                    final_price,
                    regular_price
                } = {}
            } = {},
            discountPercentage,
            formattedFinalPrice,
            mix
        } = this.props;

        if (!final_price || !regular_price) {
            return this.renderPlaceholder();
        }

        return (
            <p
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0 } }
              mix={ mix }
              aria-label={ `Product price: ${formattedFinalPrice}` }
            >
                { this.renderOldPrice() }
                { this.renderCurrentPrice() }
                { this.renderSubPrice() }
                { this.renderSchema() }
            </p>
        );
    }
}

export default ProductPrice;