/* eslint-disable */
const replaceLabel = (args, callback) => {
    const [filters] = args;
    
    filters.forEach((filter, index) => {
        if (filter.request_var == 'hks_instock'){
            filter.filter_items = filter.filter_items.filter(item => item.label && item.label != "0");
            filter.filter_items.forEach(item => {
                item.label = __('Yes');
            });
            if (!filter.filter_items.length){
                filter.filter_items.push({});
            }
        }
        if (filter.request_var == 'is_on_sale'){
            filter.filter_items = filter.filter_items.filter(item => item.label && item.label != "0");
            filter.filter_items.forEach(item => {
                item.label = __('Yes');
            });
            if (!filter.filter_items.length){
                filter.filter_items.push({});
            }
        }
    });
    filters.sort((a,b) => a.request_var == 'is_on_sale' ? -1 : b.request_var == 'is_on_sale' ? 1 : 0 );
    filters.sort((a,b) => a.request_var == 'hks_instock' ? -1 : b.request_var == 'hks_instock' ? 1 : 0 );

    return callback(...args);
}


export default {
    'Store/ProductListInfo/Reducer/reduceFilters': {
        'function': replaceLabel
    }
};