/* eslint-disable */
import Menu from 'HoeksComponent/MenuOverlay/MenuOverlay.component';

/** @namespace Component/MenuOverlay/Component */
export class MenuOverlayComponent extends Menu {

    renderSearchField() {
        return null;
    }
}

export default MenuOverlayComponent;
